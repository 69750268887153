.container {
  background-size: 1050px auto;
  @apply pb-14 xs:pb-60 md:pt-[20px] lg:pt-[40px] min-[1500px]:pt-[154px] pt-14 sm:bg-contain bg-no-repeat bg-center;
}
.tryTip {
  opacity: 0;
  animation-delay: 0.8s !important;
}
.dialogue {
  opacity: 0;
  animation-delay: 0.5s !important;
}
.happyPeople {
  opacity: 0;
  animation: scaleSlideInBottom 0.3s ease-in-out 0.1s forwards;
}
.titleAndDesc {
  opacity: 0;
  animation-delay: 0.15s !important;
}
.star {
  opacity: 0;
  animation: starIn 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
}

@keyframes scaleSlideInBottom {
  from {
    transform: scale3d(0.8, 0.8, 0.8) translateY(20%);
    opacity: 0;
  }

  to {
    transform: scale3d(1, 1, 1) translateY(0%);
    opacity: 1;
  }
}

@keyframes starIn {
  from {
    transform: scale3d(0.8, 0.8, 0.8) rotateZ(-35deg);
    opacity: 0;
  }

  50% {
    transform: scale3d(1.2, 1.2, 1.2) rotateZ(35deg);
    opacity: 1;
  }

  to {
    transform: scale3d(1, 1, 1) rotateZ(0deg);
    opacity: 1;
  }
}