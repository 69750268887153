.words {
  position: relative;
  width: 100%;
  overflow: hidden;
  @apply xs:h-[81px] h-[48px];
}

.word {
  @apply xs:text-[56px] text-[32px] xs:leading-[81px] leading-[48px] mb-[12px];
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  opacity: 0;
  transform-origin: 50% 100%;
  transform: rotateX(180deg);
}

.visible {
  position: relative;
  opacity: 1;
  transform: rotateX(0deg);
  animation: slide-in .6s;
}

.hidden {
  transform: rotateX(180deg);
  animation: slide-out .6s;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
