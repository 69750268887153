.browser {
  @apply h-full w-full flex flex-col overflow-hidden;

  header {
    @apply relative rounded-t-md;

    background-color: transparent;
    background-repeat: no-repeat, no-repeat, repeat;
    background-size: auto 100%, auto 100%, auto 100%;
    background-position: top right, top left, top right;
  }

  main {
    @apply flex-1 rounded-b-md;
    background-image: url("https://static.howxm.com/xform/bg.png");
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
