.condition {
  @apply flex grow;
  .node {
    @apply grow;
  }
  .operator {
    @apply mr-[-1px];
    &:focus-within {
      @apply z-[1];
    }
  }

  :global {
    .mantine-TextInput-input {
      border-radius: var(--mantine-radius-sm);
      @apply rounded-l-none #{!important};
    }

    .mantine-Select-input {
      border-radius: var(--mantine-radius-sm);
      @apply rounded-r-none #{!important};
    }
  }
}

